/**
 * Created by Ivan on 11.10.2024
 */

StickersCollectComponent.prototype.changeStickerToJoker = function (stickerView, delay) {
    stickerView.setCascadeOpacityEnabled(true);
    var jokerIcon = new cc.Sprite(bundles.stickers_reward_window.frames.joker_icon_png);
    stickerView.addChild(jokerIcon);
    jokerIcon.setPositionRound(stickerView.width / 2, stickerView.height / 2);
    jokerIcon.setVisible(false);

    var effect = new cleverapps.Spine(bundles.stickers_reward_window.jsons.collect_sticker_light_json);
    stickerView.addChild(effect);
    effect.setPositionRound(cleverapps.styles.StickerView.effect);

    var duplicateFont, pointsFont;
    var amount;

    if (stickerView.sticker.isRare()) {
        amount = StickersCollection.RARE_POINTS;
        duplicateFont = cleverapps.styles.FONTS.DUPLICATE_TEXT_RARE;
        pointsFont = cleverapps.styles.FONTS.STICKER_POINTS_RARE_TEXT;
    } else {
        amount = StickersCollection.REGULAR_POINTS;
        duplicateFont = cleverapps.styles.FONTS.DUPLICATE_TEXT;
        pointsFont = cleverapps.styles.FONTS.STICKER_POINTS_TEXT;
    }

    var xText = cleverapps.UI.generateOnlyText("x", pointsFont);
    xText.setAnchorPoint(0.5, 0.55);
    xText.setFontSize(xText.getFontSize() * 0.7);
    var amountText = cleverapps.UI.generateOnlyText(amount, pointsFont);
    var pointsAmount = new cleverapps.Layout([xText, amountText], {
        direction: cleverapps.UI.HORIZONTAL,
        margin: cleverapps.styles.StickerView.points.margin
    });

    jokerIcon.addChild(pointsAmount);
    pointsAmount.setPositionRound(cleverapps.styles.StickerView.points);

    var duplicateLabel = stickerView.duplicateLabel = cleverapps.UI.generateOnlyText("Duplicate.Text", duplicateFont);
    jokerIcon.addChild(duplicateLabel);
    duplicateLabel.setPositionRound(cleverapps.styles.StickerView.text);

    return new cc.Sequence(
        new cc.DelayTime(delay),
        new cc.CallFunc(function () {
            effect.setAnimation(0, "animation", false);
            effect.setCompleteListenerRemove();
            cleverapps.audio.playSound(bundles.stickers_reward_window.urls.sticker_joker_effect);
        }),
        new cc.Spawn(
            new cc.Sequence(
                new cc.ScaleTo(0.3, 0.6).easing(cc.easeIn(2)),
                new cc.ScaleTo(0.3, 1.1).easing(cc.easeOut(2))
            ),
            new cc.Sequence(
                new cc.DelayTime(0.3),
                new cc.FadeOut(0.3)
            )
        ),
        new cc.CallFunc(function () {
            stickerView.label.removeFromParent();
            stickerView.image.removeFromParent();
            stickerView.frame.removeFromParent();
            stickerView.removeBadge();

            stickerView.parent.setOpacity(255);
            jokerIcon.setVisible(true);
        }),
        new cc.ScaleTo(0.2, 1)
    );
};

RewardStickerView.prototype.createAnimation = function () {
    var stickerAnimation = new cleverapps.Spine(bundles.stickers_reward_window.jsons.collect_sticker_json);
    this.addChild(stickerAnimation);
    stickerAnimation.setPositionRound(this.width / 2, this.height / 2);
    stickerAnimation.setLocalZOrder(1000);

    return stickerAnimation;
};

RewardStickerView.prototype.receiveStickerAnimation = function (delay) {
    var stickerAnimation = this.createAnimation();
    if (this.stickerView.badge) {
        this.stickerView.badge.setVisible(false);
        this.stickerView.badge.setScale(3);
    }

    this.stickerView.setVisible(false);

    var openDelayTime = this.stickerView.sticker.isRare() ? 1 : 0;
    var actions = [
        new cc.DelayTime(delay),
        new cc.Hide(),
        new cc.TargetedAction(stickerAnimation, new cc.Sequence(
            new cc.CallFunc(function () {
                stickerAnimation.setAnimation(0, "idle_silver", true);
            }),

            new cc.CallFunc(function () {
                if (this.stickerView.sticker.isRare()) {
                    stickerAnimation.setAnimation(0, "idle_gold", true);
                    cleverapps.audio.playSound(bundles.stickers_reward_window.urls.gold_sticker_light_effect);
                }
            }.bind(this))
        )),
        AnimationsLibrary.spawn(this),
        new cc.Spawn(
            new cc.Sequence(
                new cc.DelayTime(0.5),
                new cc.CallFunc(function () {
                    this.stickerView.setVisible(true);
                }.bind(this))
            ),
            new cc.TargetedAction(stickerAnimation, new cc.Sequence(
                new cc.DelayTime(openDelayTime),
                new cc.CallFunc(function () {
                    var animation, sound;
                    if (this.stickerView.sticker.isRare()) {
                        animation = "open_gold";
                        sound = bundles.stickers_reward_window.urls.gold_sticker_open_effect;
                    } else {
                        animation = "open_silver";
                        sound = bundles.stickers_reward_window.urls.sticker_open_effect;
                    }

                    cleverapps.audio.playSound(sound);
                    stickerAnimation.setAnimation(0, animation, false);
                    stickerAnimation.setCompleteListenerRemove();
                }.bind(this)),
                new cc.DelayTime(1 - openDelayTime)
            ))
        )
    ];

    if (this.stickerView.sticker.duplicate) {
        actions.push(this.stickerCollectView.changeStickerToJoker(this.stickerView, 1));
    } else {
        actions.push(new cc.TargetedAction(this.stickerView.badge, new cc.Sequence(
            new cc.DelayTime(1.5),
            new cc.Show(),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.stickers_reward_window.urls.sticker_new_effect);
            }),
            new cc.ScaleTo(0.4, 1).easing(cc.easeBackOut())
        )));
    }

    return new cc.TargetedAction(this, new cc.Sequence(actions));
};

StickersCollectComponent.prototype.createCollectActions = function () {
    var stickersActions = [];
    this.stickersViews.forEach(function (stickerView) {
        stickersActions.push(stickerView.collectStickerAction());
    });

    return new cc.Sequence(
        new cc.DelayTime(0.3),
        new cc.Spawn(stickersActions),
        new cc.FadeOut(0.3)
    );
};

StickersPackView.prototype.createShowAction = function () {
    return new cc.TargetedAction(this, new cc.Sequence(
        new cc.Show(),
        new cc.Spawn(
            new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut()),
            new cc.FadeIn(0.5)
        )
    ));
};

StickersPackView.prototype.createOpenAction = function () {
    return new cc.CallFunc(function () {});
};

StickersPackView.prototype.createHideAction = function () {
    return new cc.TargetedAction(this, new cc.Sequence(
        new cc.Spawn(
            new cc.ScaleTo(0.5, 0.6).easing(cc.easeBackIn()),
            new cc.FadeOut(0.5)
        ),
        new cc.RemoveSelf()
    ));
};